import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzFormModule } from 'ng-zorro-antd/form';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {NgZorroAntdModule, NzCarouselModule} from 'ng-zorro-antd';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import {RxReactiveFormsModule} from "@rxweb/reactive-form-validators";
import {RoundUp} from '../pipes/RoundUp';
import {TimeAgoPipe} from '../pipes/time-ago.pipe';
import {PushNotificationsModule} from 'ng-push';
import {LocalFilterPipe} from '../pipes/local-filter.pipe';
import {Local} from 'protractor/built/driverProviders';
import {FilterByArrayFieldPipe} from '../pipes/filter-by-array-field.pipe';
import {FilterByDatePipe} from '../pipes/filter-by-date.pipe';
import {ResizableModule} from "angular-resizable-element";
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { MediaBoxComponent } from './media-box/media-box.component';
import { UploadBoxComponent } from './upload-box/upload-box.component';
// take a variable where declare all the module thats want to share with
const Modules = [
  NgZorroAntdModule,
  CommonModule,
  NzFormModule,
  ReactiveFormsModule,
  NzGridModule,
  NzSelectModule,
  FormsModule,
  NzPageHeaderModule,
  NzResultModule,
  NzListModule,
  NzAnchorModule,
  RxReactiveFormsModule,
  PushNotificationsModule,
  NzCarouselModule,
  ResizableModule
];

@NgModule({
  declarations: [RoundUp, TimeAgoPipe, LocalFilterPipe, FilterByArrayFieldPipe, FilterByDatePipe, FilePreviewComponent, MediaBoxComponent, UploadBoxComponent],
  imports: [...Modules],
  exports: [...Modules, RoundUp, TimeAgoPipe, LocalFilterPipe, FilterByArrayFieldPipe, FilterByDatePipe, FilePreviewComponent, MediaBoxComponent, UploadBoxComponent]


})
export class SharedModuleModule { }
