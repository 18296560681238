import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../common/services/auth.service';
import {User} from '../../common/models/user';
import {Router} from '@angular/router';
import { MenuService } from 'src/app/common/services/menu.service';
import {BreadcrumbService} from '../../common/services/breadcrumb.service';
import { StartChatService } from 'src/app/common/services/start-chat.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {
  userDetails: User;
  menuData: any[] = [];
  currentUrl = '';
  isCollapsed = false;
  breadcrumbArr: any[] = [];
  chatIconType: string = 'message';
  currentRfq = '';
  masterOpen = true;
  constructor(
    private authService: AuthService,
    private router: Router,
    private menuService: MenuService,
    private breadcrumbService: BreadcrumbService,
    private startChatService: StartChatService
  ) { }

  ngOnInit() {
    this.userDetails = AuthService.getUserDetails();
    this.menuData = this.getMenu();
    this.currentUrl = this.router.url;
    this.authService.authState.subscribe(loggedIn => {
      this.userDetails = AuthService.getUserDetails();
      this.menuData = this.getMenu();
    });
    this.breadcrumbService.onBreadcrumb.subscribe(data => {
      this.breadcrumbArr = data;
    });
    this.startChatService.openRFQ.subscribe(data => {
      this.currentRfq = data !== '0' ? data : '';
      console.log('inside', this.currentRfq);

    })
  }

  getMenu(): any[] {
    if(AuthService.isSuperAdmin() || AuthService.isAdmin()) {
      return this.menuService.getLeftMenu();
    }
    const otherUsersMenuItems = ['dashboard', 'newjob', 'myJobs', 'invitedVendors'];
    if(this.userDetails['subRole'] !== 'master') {
      otherUsersMenuItems.push('jobRequests');
    }
    return this.menuService.getLeftMenu(otherUsersMenuItems);
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/auth/login');
  }

  profile() {
    this.router.navigateByUrl('/panel/user/profile');
  }

  goToLink(url: string) {
    console.log(url)
    this.router.navigateByUrl(url);
  }

  onMenuOpenClose(event) {
    this.masterOpen = !this.masterOpen;
  }

  openChat() {
    this.chatIconType = 'loading';
    setTimeout(() => {
      this.chatIconType = 'message';
    }, 3000)
    this.startChatService.openChat.next();
  }

}
