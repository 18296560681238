import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ChatDispatcherService } from 'src/app/main/chat/services/chat-dispatcher.service';
import { RequesterService } from './requester.service';

@Injectable({
  providedIn: 'root'
})
export class StartChatService {
  openChat: Subject<any> = new Subject<any>();
  openRFQ: Subject<string> = new Subject<string>();
  currentRfq: string = '';
  constructor(
    private chatDispatcherService: ChatDispatcherService,
    private requesterService: RequesterService
  ) {
    this.openRFQ.subscribe(rfq => {
      this.currentRfq = rfq;
      console.log('CURRENT RFQ', this.currentRfq)
    });

    this.openChat.subscribe(rfq => {
      this.requesterService.request('get', 'jobs/rfqs/' + this.currentRfq + '/initiate-chat').subscribe(data => {
        this.chatDispatcherService.createOrUpdateChannel(data.data.channel);
      });
    });

   }
}
