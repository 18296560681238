import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {RequesterService} from '../../../../common/services/requester.service';
import * as format from 'date-fns/format';
import { StatusService } from 'src/app/common/services/status.service';
@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  pageSize = 3;
  @Input() searchText: Observable<string>;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onClear: EventEmitter<boolean> = new EventEmitter<boolean>();
  dateFormat = 'dd/MM/yyyy';
  dateFilter: any[] = [];
  dateArray: string[];
  listOfSelectedStatus: string[] = [];
  listOfStatus: string[] = [];
  listOfPorts: any[] = [];
  listOfSelectedPorts: string[] = [];
  keyword: string;
  subscription: Subscription;
  advanceSearch = false;
  searchResults: any = {
    rfqs: {
      name: 'Jobs',
      loading: true,
      data: [],
      display: true
    },
    pos: {
      name: 'POs',
      loading: true,
      data: [],
      display: true
    },
    invoices: {
      name: 'Invoices',
      loading: true,
      data: [],
      display: true
    }
  };

  listOfVendors: string[] = [];
  selectedVendors: string[] = [];
  constructor(
    private requesterService: RequesterService,
    public statusService: StatusService
  ) { }

  get isNoData(): boolean {
    return Object.values(this.searchResults).every((x: any) => !x.data.length);
  }

  get isLoading(): boolean {
    return Object.values(this.searchResults).some((x: any) => x.loading);
  }

  ngOnInit() {
    this.subscription = this.searchText
      .pipe(
        debounceTime(1000)
      )
      .subscribe(async keywords => {
        this.keyword = keywords;
        // console.log('KEYWORD', this.keyword)
        await this.searchByFilters();
      });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async getRfqList(params: any) {
    try {
      this.searchResults.rfqs.loading = true;
      if (!this.keyword) {
        this.clearSearch();
        return new Promise<any[]>(resolve => resolve([]));
      }
      this.searchResults.rfqs.data = await this.requesterService.request('get', 'jobs/rfqs', params)
        .pipe(map(x => x.data)).toPromise();
      this.searchResults.rfqs.loading = false;

      this.searchResults.pos.data = this.searchResults.rfqs.data.filter(x => !!x.po);
      this.searchResults.pos.loading = false;
      console.log(this.searchResults)
      return;
    } catch (e) {
      throw e;
    }
  }

  async getInvoiceList(params: any) {
    try {
      this.searchResults.invoices.loading = true;
      if (!this.keyword) { return new Promise<any[]>(resolve => resolve([])); }
      this.searchResults.invoices.data = await this.requesterService.request('get', 'jobs/invoices', {...params, rfq: this.searchResults.rfqs.data.map(x => x._id)})
        .pipe(map(x => x.data)).toPromise();
      this.searchResults.invoices.loading = false;
      return;
    } catch (e) {
      throw e;
    }
  }
  async searchByFilters() {
    const params: any = {search: this.keyword};
    const dateArr = this.dateFilter.map(x => format(x, 'YYYY-MM-DD'));
    if (dateArr.length) {
      params.startDate = dateArr[0];
      params.endDate = dateArr[1];
    }
    if (localStorage.getItem('searchKeyword')) {
      params.search = localStorage.getItem('searchKeyword');
      if (localStorage.getItem('searchParams') && Object.keys(JSON.parse(localStorage.getItem('searchParams'))).length) {
        params.startDate = JSON.parse(localStorage.getItem('searchParams')).startDate;
        params.endDate = JSON.parse(localStorage.getItem('searchParams')).endDate;
        this.dateFilter = [new Date(params.startDate), new Date(params.endDate)];
      }
      this.clearSearch();
    }
    await this.getRfqList(params);
    this.getInvoiceList(params)
    // await Promise.all([this.getRfqList(params), this.getInvoiceList(params)]);
    this.initLoadFilters();
  }

  initLoadFilters() {
    this.listOfStatus = [];
    this.listOfSelectedPorts = [];
    this.listOfPorts = [];
    this.listOfSelectedStatus = [];

    this.selectedVendors = [];
    this.listOfVendors = [];

    this.searchResults.rfqs.data.map(x => {
      this.addToPortList(x.portsDetails);
      if (!this.listOfStatus.includes(x.jobStatus)) {
        this.listOfStatus.push(x.jobStatus);
      }
    });
    this.listOfSelectedStatus = [ ...this.listOfStatus];

    this.searchResults.invoices.data.forEach(item => {
      if (!this.listOfVendors.includes(item.vendorCompanyName)) {
        this.listOfVendors.push(item.vendorCompanyName);
      }
    });
    this.searchResults.pos.data.forEach(item => {
      if (!this.listOfVendors.includes(item.awardedBidVendor)) {
        this.listOfVendors.push(item.awardedBidVendor);
      }
    });
    this.selectedVendors = [ ...this.listOfVendors];


  }

  close() {
    localStorage.setItem('searchKeyword', this.keyword);
    const dateArr = this.dateFilter.map(x => format(x, 'YYYY-MM-DD'));
    const params: any = {};
    if (dateArr.length) {
      params.startDate = dateArr[0];
      params.endDate = dateArr[1];
      localStorage.setItem('searchParams', JSON.stringify(params));
    }
    this.onClose.emit(true);
  }

  clearSearch() {
    localStorage.removeItem('searchParams');
    localStorage.removeItem('searchKeyword');
  }

  async onClearEmit() {
    this.keyword = '';
    this.onClear.emit(true);
    await this.searchByFilters();
  }

  resultInChange(section: string, enabled: boolean) {
    this.searchResults[section].display = enabled;
  }

  addToPortList(portsData: any[]) {
    // this.listOfPorts.push( ...portsData.filter(x => !this.listOfPorts.map(p => p.value).includes(x.unlocs)).map(x => ({label: x.name, value: x.unlocs})));
    // this.listOfSelectedPorts = this.listOfPorts.map(x => x.value);
    // console.log(this.listOfSelectedPorts)
  }

}
