const data = [
    {
        key: 'dashboard',
        title: 'Dashboard',
        route: '/panel/dashboard',
        icon: 'appstore',
    },
    {
        key: 'newjob',
        title: 'New Job',
        route: '/panel/new-job',
        icon: 'plus',
        info: 'New Job',

    },
  {
    key: 'myJobs',
    title: 'My Jobs',
    route: '/panel/request/my-jobs',
    icon: 'star',
    info: 'My All Jobs',
  },
  {
    key: 'jobRequests',
    title: 'Approval Requests',
    route: '/panel/request/job-requests',
    icon: 'right',
    info: 'Pending Approval',

  },
  {
    key: 'invitedVendors',
    title: 'Invited Vendors',
    route: '/panel/request/invitedRequest',
    icon: 'user',
    info: 'Invited Vendors',

  },
    {
        key: 'config',
        title: 'Configuration',
        route: '',
        icon: 'setting',
        children:
            [
                {
                    key: 'basicInfo',
                    title: 'Update Companies',
                    route: '/auth/post-register?page=1',
                    icon: 'profile'
                },
                {
                    key: 'addShips',
                    title: 'Add Ships',
                    route: '/auth/post-register?page=2',
                    icon: 'form'
                }, {
                    key: 'user',
                    title: 'Manage Users',
                    route: '/auth/post-register?page=3',
                    icon: 'user'
                }, {
                    key: 'assign',
                    title: 'Assign',
                    route: '/auth/post-register?page=4',
                    icon: 'file-sync'
                },
                {
                    key: 'termsAndConditions',
                    title: 'Terms and conditions',
                    route: '/auth/post-register?page=5',
                    icon: 'global'
                },

            ]
    },
];

export { data };
