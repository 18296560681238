import {Injectable} from '@angular/core';
import {User, UserModel} from '../models/user';
import {RequesterService} from './requester.service';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import { StreamClientService } from './stream-client.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public authState: Subject<boolean> = new Subject<boolean>();
  localStorageTokenFieldName = 'bearerToken';
  localStorageTokenExpiryFieldName = 'bearerTokenExpiry';
  localStorageUserFieldName = 'userDetails';
  bearerToken = null;
  bearerTokenExpiry = null;
  userDetails: User | null = null;

  constructor(
    private requesterService: RequesterService,
    private streamClient: StreamClientService
  ) {
    this.getDataFromLocalStorage();
    // console.log(this.bearerToken, this.bearerTokenExpiry, this.userDetails)
    if (AuthService.masterIsLoggedIn) {
      this.streamClient.connect();
      this.authState.next(true);
    } else {
      this.authState.next(false);
    }
  }

  public static get masterIsLoggedIn(): boolean {
    let bearerToken = null;
    let bearerTokenExpiry = null;
    let userDetails: User | null = null;
    if (localStorage.getItem('bearerToken')) {
      bearerToken = localStorage.getItem('bearerToken');
      if (localStorage.getItem('bearerTokenExpiry')) {
        bearerTokenExpiry = localStorage.getItem('bearerTokenExpiry');
      }
    }
    if (localStorage.getItem('userDetails')) {
      userDetails = new User(JSON.parse(localStorage.getItem('userDetails')));
    }
    // console.log(bearerToken, bearerTokenExpiry > (new Date()).getTime(),  !!userDetails);
    if (!(bearerToken && bearerTokenExpiry > (new Date()).getTime() && !!userDetails)) {
      AuthService.clearDataFromLocalStorage();
    }
    const app = localStorage.getItem('app');
    if (app !== 'owner') {
      AuthService.clearDataFromLocalStorage();
      return false;
    }
    return !!(bearerToken && bearerTokenExpiry > (new Date()).getTime() && !!userDetails);
  }

  public get isLoggedIn(): boolean {
    return !!(this.bearerToken && this.bearerTokenExpiry > (new Date()).getTime() && !!this.userDetails);
  }

  public static getUserDetails(): User | null {
    if (localStorage.getItem('userDetails')) {
      return new User(JSON.parse(localStorage.getItem('userDetails')));
    }
    return null;
  }

  public static isSuperAdmin(): boolean {
    if (localStorage.getItem('superAdmin')) {
      return JSON.parse(localStorage.getItem('superAdmin'));
    }
    return false;
  }

  public static isAdmin(): boolean {
    if (localStorage.getItem('admin')) {
      return JSON.parse(localStorage.getItem('admin'));
    }
    return false;
  }

  public static clearDataFromLocalStorage() {
    const localStorageTokenFieldName = 'bearerToken';
    const localStorageTokenExpiryFieldName = 'bearerTokenExpiry';
    const localStorageUserFieldName = 'userDetails';
    localStorage.removeItem(localStorageTokenFieldName);
    localStorage.removeItem(localStorageTokenExpiryFieldName);
    localStorage.removeItem(localStorageUserFieldName);
    localStorage.removeItem('superAdmin');
    localStorage.removeItem('admin');
    localStorage.removeItem('app');
    localStorage.clear();
  }

  getDataFromLocalStorage() {
    if (localStorage.getItem(this.localStorageTokenFieldName)) {
      this.bearerToken = localStorage.getItem(this.localStorageTokenFieldName);
      if (localStorage.getItem(this.localStorageTokenExpiryFieldName)) {
        this.bearerTokenExpiry = localStorage.getItem(this.localStorageTokenExpiryFieldName);
      }
    }
    if (localStorage.getItem(this.localStorageUserFieldName)) {
      this.userDetails = new User(JSON.parse(localStorage.getItem(this.localStorageUserFieldName)));
    }
  }

  setDataToLocalStorage(loginResponse) {
    localStorage.setItem(this.localStorageTokenFieldName, loginResponse.token);
    localStorage.setItem(this.localStorageTokenExpiryFieldName, (new Date(loginResponse.expireAt)).getTime().toString());
    localStorage.setItem('superAdmin', String(loginResponse.user.superAdmin));
    localStorage.setItem('admin', String(loginResponse.user.superAdmin || !!loginResponse.user.admin));
    this.setUserDataInLocalStorage(loginResponse.user);
    localStorage.setItem('app', 'owner');
  }

  setUserDataInLocalStorage(userDetails: User) {
    localStorage.setItem(this.localStorageUserFieldName, JSON.stringify(new User(userDetails)));
  }

  public login(email: string, password: string): Observable<any> {
    return this.requesterService.request('post', 'auth/login', {email, password}, false)
      .pipe(
        map((apiResponse: any) => {
          if (apiResponse.data) {
            console.log(apiResponse.data);
            this.setDataToLocalStorage(apiResponse.data);
            this.authState.next(true);
            this.streamClient.connect();
          }
          return apiResponse;
        })
      );
  }


  public verifyRegistration(token: string): Observable<any> {
    AuthService.clearDataFromLocalStorage();
    return this.requesterService.request('post', 'auth/verify-email', {token}, false)
      .pipe(
        map((apiResponse: any) => {
          if (apiResponse.data) {
            this.setDataToLocalStorage(apiResponse.data);
            this.authState.next(true);
          }
          return apiResponse;
        })
      );
  }

  // public logout() {
  //   // API CAll
  //   AuthService.clearDataFromLocalStorage();
  //   this.authState.next(false);
  // }

  public logout(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.requesterService.request('get', 'auth/logout').subscribe(loggedOut => {
        AuthService.clearDataFromLocalStorage();
        this.authState.next(false);
        resolve(true);
      }, error => {
        AuthService.clearDataFromLocalStorage();
        this.authState.next(false);
        resolve(true);
      });
    });
  }


}
