import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {

  @Input() src: string;
  @Input() fileName?: string;
  @Input() width: string = '100%';
  @Input() height?: string;
  apiBaseUrl = environment.apiUrl + '/uploads/';
  dynamicStyle: any = {};
  type: string = 'image';
  brokenImage = false;
  @Input() deleteEnabled: boolean = false;
  @Input() downloadEnabled: boolean = true;
  @Input() selectEnabled: boolean = false;

  @Output() fileDelete: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() {

  }

  ngOnInit() {
    const ext = this.src.slice((this.src.lastIndexOf(".") - 1 >>> 0) + 2);
    this.type = ['png', 'jpg', 'jpeg', 'gif'].includes(ext) ? 'image': 'file';
    this.dynamicStyle.width = this.width;
    if( this.height) {
      this.dynamicStyle.height = this.height;
    }
  }
  defaultImage(e) {
    console.log(e);
    this.brokenImage = true;
  }

  downloadFile() {
    window.open(this.apiBaseUrl + this.src);
  }

}
