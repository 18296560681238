import { Injectable } from '@angular/core';
import { connect, StreamClient } from 'getstream';
import { Observable, Subject } from 'rxjs';
import { RequesterService } from './requester.service';

interface ZeaboxNotification {
  notificationsByGroup: any[],
  unseen: number,
  unread: number
}

@Injectable({
  providedIn: 'root',
})
export class StreamClientService {
  client: StreamClient;
  subscription: any;
  localUser: any;
  notificationFeed: any;
  $notification: Subject<any> = new Subject<any>();
  userRole = 'owner';
  $allNotifications: Subject<ZeaboxNotification> = new Subject<ZeaboxNotification>();
  constructor(private requesterService: RequesterService) {
    // Instantiate a new client (client side)
  }

  getRealtimeNotification(): Observable<any>{
    return this.$notification as Observable<any>;
  }

  async getAllNotifications(page: number = 1, limit: number = 15){
    try {
      const result = await this.notificationFeed.get({ limit, skip: (page - 1) * limit });
      const zeaboxNotification: ZeaboxNotification = {
        notificationsByGroup: result.results,
        unread: result.unread,
        unseen: result.unseen
      };
      this.$allNotifications.next(zeaboxNotification);
      console.log('LOGGING');
    } catch(e) {
      throw e;
    }
  }

  async markNotificationAsRead(notificationIds: string[]) {
    this.notificationFeed.get({ mark_read: notificationIds});
    this.getAllNotifications();
  }

  async connect() {
    try {
      const response = await this.requesterService.request('get', 'auth/authenticate-activity').toPromise();
      const {token, appId, appToken} = response.data;
      this.client = connect(appToken, token, appId);
      this.localUser = JSON.parse(localStorage.getItem('userDetails') || '');
      const userId = this.userRole + '_' + this.localUser._id;
      this.notificationFeed = this.client.feed('notification', userId, token);
      this.subscription = this.notificationFeed.subscribe((data) => {
        for (const item of data.new) {
          this.$notification.next(item);
        }
        this.getAllNotifications();

      });
      this.getAllNotifications();
    } catch(e) {
      console.log(e)
    }
  }



  async disconnect(){
    if(this.subscription) {
      this.subscription.cancel();
    }
  }
}
